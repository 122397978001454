module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-R845R93N85"],"pluginConfig":{"head":false,"respectDNT":true,"delayOnRouteUpdate":400,"exclude":[],"origin":"https://www.googletagmanager.com"},"gtagConfig":{"anonymize_ip":true,"cookie_expires":0,"send_page_view":true}},
    },{
      plugin: require('../node_modules/gatsby-v2-plugin-page-transitions/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/seta-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"af58b6f6f5db6ab5c3b0bd74c62fb8ed"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Seta Workshop - Attention to every dot","short_name":"Seta Workshop","start_url":"/","background_color":"#FFF","theme_color":"#E7222E","display":"minimal-ui","icon":"src/images/seta-logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"af58b6f6f5db6ab5c3b0bd74c62fb8ed"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
