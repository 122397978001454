import './src/fonts/_fonts.scss';
import './src/styles/_buttons.scss';
import './src/styles/index.scss';
import { AnimatePresence } from 'framer-motion';
import React from "react"

export const wrapPageElement = ({ element }) => <AnimatePresence mode="wait">{element}</AnimatePresence>;

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // transition duration from `layout.js` * 1000 to get time in ms
  // * 2 for exit + enter animation
  const TRANSITION_DELAY = 400

  window.setTimeout(() => window.scrollTo(0, 0), TRANSITION_DELAY)
  // if it's a "normal" route
  if (location.action === "PUSH") {
  }

  // if we used the browser's forwards or back button
  else {
    const savedPosition = getSavedScrollPosition(location) || [0, 0]

    window.setTimeout(() => window.scrollTo(...savedPosition), TRANSITION_DELAY)
  }

  return false
}
